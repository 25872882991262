import React from "react";
import { AutoComplete } from "../AutoComplete";
import { DateRange } from "../DateRange";
import { CustomButton } from "../CustomButton";
import { Col, Row } from "reactstrap";

const ListingFilterBar = ({
    loading = false,
    isMulti = false,
    startDate = null,
    endDate = null,
    dropDownOptions = [],
    dropDownValue = "",
    dropDownPlaceholder = "",
    onFilterClick = () => {},
    handleDateChange = () => {},
    handleDropDownChange = () => {},
    disableFilterBtn = false
}) => {
    return (
        <div>
            <Row>
                <Col sm={12} md={12} lg={6}>
                    <AutoComplete
                        placeholder={dropDownPlaceholder}
                        options={dropDownOptions}
                        isMulti={isMulti}
                        value={dropDownValue}
                        onChange={handleDropDownChange}
                    />
                </Col>
                <Col sm={12} md={12} lg={6}>
                    <DateRange
                        onChange={handleDateChange}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ListingFilterBar;
