import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { TextInput } from "../../components/Input";
import { CustomButton } from "../../components/CustomButton";
import emailIcon from "../../assets/images/app/adduser/email-curved.svg";
import KeyOutline from "../../assets/images/app/refund/key_outline.svg";
import userIcon from "../../assets/images/app/auth-page/user-1_curved.svg";
import eyeOpenIcon from "../../assets/images/app/auth-page/eye-open_curved.svg";
import passwordIcon from "../../assets/images/app/auth-page/lock-on_curved.svg";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

function AddUserModal({ isLoading, closeModalHandler, onSubmit = () => {} }) {
    const [passwordFieldType, setPasswordFieldType] = useState(false);

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            name: "",
            approvalCode: ""
        },

        validationSchema: Yup.object({
            name: Yup.string()
                .required("Username is required")
                .test("is-not-email", "Username cannot be an email", function (value) {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    return !emailRegex.test(value);
                }),
            email: Yup.string().email("Enter a valid email").required("Enter email"),
            password: Yup.string().required("Enter password"),
            approvalCode: Yup.string()
                .required("Enter approval code")
                .matches(/^[^\s]+$/, "Approval code cannot contain spaces !")
        }),
        onSubmit: (values) => onSubmit(values)
    });

    return (
        <>
            <div className="px-5 mx-5 app-add-manager-container">
                <h1 className="auth-heading m-0 p-0 text-center">Add New Manager</h1>
                <form className="mt-5" onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <TextInput
                            autoComplete={"true"}
                            placeholder="Username"
                            name={"name"}
                            type="text"
                            className="hide-default-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                            iconBeforeText={userIcon}
                        />
                        <FormikErrorText fieldName={"name"} formikInstance={formik} />
                    </div>
                    <div className="mb-4">
                        <TextInput
                            autoComplete={"true"}
                            placeholder="Email"
                            name={"email"}
                            type="email"
                            className="hide-default-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            iconBeforeText={emailIcon}
                        />
                        <FormikErrorText fieldName={"email"} formikInstance={formik} />
                    </div>

                    <div className="mb-4">
                        <TextInput
                            placeholder="Password"
                            type={!passwordFieldType ? "password" : "text"}
                            className="hide-default-icon"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            iconBeforeText={passwordIcon}
                            iconClickHandler={passwordFieldChangeHandler}
                            icon={eyeOpenIcon}
                        />
                        <FormikErrorText fieldName={"password"} formikInstance={formik} />
                    </div>

                    <div className="mb-4">
                        <TextInput
                            autoComplete={"true"}
                            placeholder="Provide Approval Code"
                            name={"approvalCode"}
                            type="text"
                            className="hide-default-icon"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.approvalCode}
                            iconBeforeText={KeyOutline}
                        />
                        <FormikErrorText fieldName={"approvalCode"} formikInstance={formik} />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="w-100 mt-4 me-3 back-btn" onClick={closeModalHandler}>
                            Back
                        </span>

                        <CustomButton
                            loading={isLoading}
                            type="submit"
                            color="primary"
                            title="Add Manager"
                            className="w-100 mt-4 ms-3"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddUserModal;
