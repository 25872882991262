import React from "react";

import CustomModal from "./CustomModal";
import { CustomButton } from "../CustomButton";
import { CONFIRMATION_MODAL_CONFIG, getConfirmationModalConfig } from "./ConfirmationModalConfig";

const ConfirmationModal = ({
    isOpen = false,
    modalType = "DELETE",
    hasCustomConfig = false,
    customTitle = "",
    customYesBtnText = "",
    customNoBtnText = "",
    customImageSrc = "",
    modalBodyClasses = "",
    yesBtnClasses = "",
    noBtnClasses = "",
    onYes = () => {},
    onNo = () => {},
    ...rest
}) => {
    let customConfigObj = hasCustomConfig
        ? {
              customTitle,
              customYesBtnText,
              customNoBtnText,
              customImageSrc
          }
        : null;

    let Config = getConfirmationModalConfig(CONFIRMATION_MODAL_CONFIG, modalType, customConfigObj);
    return (
        <div>
            <CustomModal isOpen={isOpen} {...rest}>
                <div
                    className={`d-flex flex-column align-items-center px-5 py-3  confirmation-modal ${modalBodyClasses}`}
                >
                    <div className="mb-5 pb-3 confirmation-typo px-3">
                        <p className="title-typo fw-bold">{Config.Title}</p>
                        {Config.SubText && <p className="my-4 subtext-typo">{Config.SubText}</p>}
                    </div>

                    <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                        <CustomButton
                            title={Config.YesButtonText}
                            color="primary"
                            className={`me-3 custom-btn-sm shadow-theme fw-bold w-100 ${yesBtnClasses}`}
                            onClick={onYes}
                        />
                        <CustomButton
                            title={Config.NoButtonText}
                            className={`ms-3 custom-btn-sm fw-bold w-100 ${noBtnClasses}`}
                            color="primary"
                            outline
                            onClick={onNo}
                        />
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ConfirmationModal;
