import React, { useMemo, Fragment } from "react";
import {
    Col,
    Pagination as PaginationContainer,
    PaginationItem,
    PaginationLink,
    Row
} from "reactstrap";

import { RowsLimit } from "../RowsLimit";
import { SortingOptions } from "../../constants";
import Config from "../../Config";

const MAX_PAGE_VIEW_LIMIT = 4;

const Pagination = (props) => {
    const {
        count,
        currentPage,
        totalData,
        rowLimit = Config.LIMIT,
        onPageClick,
        handleChange = () => {}
    } = props;
    const totalPages = useMemo(() => {
        return Math.ceil(totalData / rowLimit);
    }, [totalData, rowLimit]);

    const handlePageClick = (val) => {
        if (onPageClick && typeof onPageClick === "function") {
            onPageClick(val);
        }
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            handlePageClick(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            handlePageClick(currentPage + 1);
        }
    };

    const renderPages = () => {
        let pages = [];

        let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_VIEW_LIMIT / 2));
        let endPage = Math.min(totalPages, startPage + MAX_PAGE_VIEW_LIMIT - 1);

        if (endPage - startPage < MAX_PAGE_VIEW_LIMIT - 1) {
            startPage = Math.max(1, endPage - MAX_PAGE_VIEW_LIMIT + 1);
        }

        if (startPage > 1) {
            pages.push(
                <PaginationItem key={1}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(1)}>
                        1
                    </PaginationLink>
                </PaginationItem>
            );

            if (startPage > 2) {
                pages.push(
                    <PaginationItem key="ellipsis-start">
                        <PaginationLink tag="button">...</PaginationLink>
                    </PaginationItem>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <PaginationItem active={i === currentPage} key={i}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(i)}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(
                    <PaginationItem key="ellipsis-end">
                        <PaginationLink tag="button">...</PaginationLink>
                    </PaginationItem>
                );
            }

            pages.push(
                <PaginationItem key={totalPages}>
                    <PaginationLink tag="button" onClick={() => handlePageClick(totalPages)}>
                        {totalPages}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        return pages;
    };

    return (
        <Row>
            <Col
                sm={12}
                md={6}
                lg={6}
                className="d-flex justify-content-md-start justify-content-sm-center"
            >
                <div className="py-1 mt-5">
                    {totalData && (
                        <RowsLimit
                            options={SortingOptions}
                            changeHandler={handleChange}
                            total={totalData}
                            count={count}
                        />
                    )}
                </div>
            </Col>
            <Col
                sm={12}
                md={6}
                lg={6}
                className="d-flex justify-content-md-end justify-content-sm-center"
            >
                {totalPages > 1 && (
                    <div className=" bg-white d-flex justify-content-between align-items-center py-1 mt-5">
                        <PaginationContainer className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationItem disabled={currentPage === 1}>
                                <PaginationLink previous tag="button" onClick={handlePreviousClick}>
                                    <i className="bx bx-chevron-left"></i>
                                </PaginationLink>
                            </PaginationItem>
                            {renderPages()}

                            <PaginationItem disabled={currentPage === totalPages}>
                                <PaginationLink next tag="button" onClick={handleNextClick}>
                                    <i className="bx bx-chevron-right"></i>
                                </PaginationLink>
                            </PaginationItem>
                        </PaginationContainer>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default Pagination;
