import * as Yup from "yup";
import { validateDecimalLimit } from "../../utils/commonUtils";

export const initialValues = {
    invoice: "",
    email: "",
    amount: ""
};

export const validationSchema = Yup.object({
    invoice: Yup.string()
        .required("Enter invoice number")
        .test("is-not-email", "Invoice number cannot be an email", function (value) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return !emailRegex.test(value);
        })
        .matches(
            /^[a-zA-Z0-9_-]+$/,
            "Invoice number can only contain letters, numbers, hyphens, and underscores"
        ),
    email: Yup.string().email("Enter a valid email"),
    amount: Yup.number("Enter amount")

        .positive("Amount can't have any special characters, negative or 0 values")
        .test("is-in-range", "Amount could not be more than 9 digits", (value) => {
            let hasDecimalValue = value.toString().split(".");
            let valueToValidate = value;
            if (hasDecimalValue[1]) {
                valueToValidate = hasDecimalValue[0];
            }

            return valueToValidate.toString().length <= 9;
        })
        .test("deciman-units", "Can't add more the 2 none-zero decimal digits!", (value) => {
            if (value.toString().indexOf(".") == -1) return true;
            return validateDecimalLimit(value, 2, 1);
        })
        .required("Enter valid amount")
});
