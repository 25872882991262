import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ChangeApprovalCodeModal } from "../../../pages/Modals";
import { ChangePassword } from "../../../pages/Authentication";
import { ConfirmationModal, CustomModal } from "../../CustomModal";
import { SIGN_OUT } from "../../../store/actions";
import modalLogoutImg from "../../../assets/images/app/common/modal-logout-img.svg";
import "./ProfileMenu.scss";

const ProfileMenu = (props) => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [menu, setMenu] = useState(false);
    const [isChangePasswordModalActive, setIsChangePasswordModalActive] = useState(false);
    const [isApprovalCodeModalActive, setIsApprovalCodeModalActive] = useState(false);

    const handleLogoutModal = () => {
        setShowLogoutModal(true);
    };

    const handleSignout = () => {
        const { signOut } = props;
        localStorage.clear();
        signOut();
    };

    // change password modal close handling
    const handleChangePasswordModalClose = () => {
        setIsChangePasswordModalActive(false);
    };

    // approval code modal close handling
    const handleApprovalCodeModalClose = () => {
        setIsApprovalCodeModalActive(false);
    };

    return (
        <>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block profile-menu-con"
            >
                <DropdownToggle
                    className="btn header-item px-0"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <span className="menu-icon-con">
                        <i className="bx bx-menu "></i>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <div className="" />
                    <div
                        className="dropdown-item"
                        role="button"
                        onClick={() => setIsChangePasswordModalActive(true)}
                    >
                        <span>{"Change Password"}</span>
                    </div>
                    <div
                        className="dropdown-item"
                        role="button"
                        onClick={() => setIsApprovalCodeModalActive(true)}
                    >
                        <span>{"Change Approval Code"}</span>
                    </div>
                    <div className="dropdown-item" role="button" onClick={handleLogoutModal}>
                        <span>{"Logout"}</span>
                    </div>
                </DropdownMenu>
            </Dropdown>

            <ConfirmationModal
                modalBodyClasses="logout-modal"
                noBtnClasses="logout-modal-no-btn"
                isOpen={showLogoutModal}
                hasCustomConfig
                size="md"
                onNo={() => setShowLogoutModal(false)}
                onYes={() => handleSignout()}
                customTitle="Are you sure you want to logout?"
                customYesBtnText="Yes"
                customNoBtnText="No"
                customImageSrc={modalLogoutImg}
            />
            <CustomModal
                isOpen={isChangePasswordModalActive}
                toggle={handleChangePasswordModalClose}
                size="md"
            >
                <ChangePassword closeModalHandler={handleChangePasswordModalClose} isCurrentUser />
            </CustomModal>

            <CustomModal
                isOpen={isApprovalCodeModalActive}
                toggle={handleApprovalCodeModalClose}
                size="md"
            >
                <ChangeApprovalCodeModal closeModalHandler={handleApprovalCodeModalClose} />
            </CustomModal>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch({ type: SIGN_OUT })
});

export default connect(null, mapDispatchToProps)(withRouter(ProfileMenu));

ProfileMenu.propTypes = {
    signOut: PropTypes.any
};
